<template>
  <div>
    <help-popup ref="popup-levels1">
      <table width="70" border="1" cellpadding="1" cellspacing="1">
        <tbody>
          <tr>
            <td><b>Level</b></td>
            <td><b>Required U-Score</b></td>
            <td><b>Leveling Reward</b></td>
          </tr>
          <tr>
            <td>5</td>
            <td>390</td>
            <td>4 Backpack Slots</td>
          </tr>
          <tr>
            <td>10</td>
            <td>2,170</td>
            <td>Brown Shoulder Owl</td>
          </tr>
          <tr>
            <td>15</td>
            <td>6,420</td>
            <td>6 Backpack Slots</td>
          </tr>
          <tr>
            <td>20</td>
            <td>13,270</td>
            <td>Permanent Speed Boost</td>
          </tr>
          <tr>
            <td>25</td>
            <td>24,520</td>
            <td>10 Backpack Slots</td>
          </tr>
          <tr>
            <td>30</td>
            <td>40,870</td>
            <td>Brown Cape</td>
          </tr>
          <tr>
            <td>35</td>
            <td>63,370</td>
            <td>20 Backpack Slots</td>
          </tr>
          <tr>
            <td>40</td>
            <td>92,120</td>
            <td>Nexus Force Cape</td>
          </tr>
        </tbody>
      </table>
    </help-popup>
    <help-popup ref="popup-levels2">
      <table width="70" border="1" cellpadding="1" cellspacing="1">
        <tbody>
          <tr>
            <td><b>Item</b></td>
            <td><b>Required Level</b></td>
          </tr>
          <tr>
            <td>Rank 1 Book</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Rank 1 Gear</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Rank 2 Book</td>
            <td>10</td>
          </tr>
          <tr>
            <td>Rank 2 Gear</td>
            <td>15</td>
          </tr>
          <tr>
            <td>Rank 3 Book</td>
            <td>25</td>
          </tr>
          <tr>
            <td>Rank 3 Gear</td>
            <td>30</td>
          </tr>
          <tr>
            <td>Spike Hammer</td>
            <td>30</td>
          </tr>
          <tr>
            <td>Valiant Weapons</td>
            <td>35</td>
          </tr>
          <tr>
            <td>Bat Lord Set</td>
            <td>35</td>
          </tr>
          <tr>
            <td>Valiant Capes</td>
            <td>40</td>
          </tr>
        </tbody>
      </table>
    </help-popup>
    <slideshow-module :slides="slides"></slideshow-module>
    <text-module>
      <div style="display: block; margin: 0px 10px 20px; color: #888888">
        <!-- UniverseTheGame/images/picD676D73A7D89D736C242219ED2E9B9FB.png -->
        <img
          style="padding-top: 40px"
          src="@/assets/the-game/faction-gear.png"
          align="right"
        />
        <br />
        <h2 style="font-size: 24px">Leveling</h2>
        <br />Complete missions and achievements to earn U-Score points for
        leveling up. <br />LEGO® Universe is filled with hundreds of exciting
        missions and awesome achievements. As you complete these, your
        minifigure will level up, and earn you new abilities and rewards that
        will make your minifigure even cooler and stronger!
        <br />
        <br />Unlock a permanent speed boost, a rocking owl companion, the
        amazing Nexus Force Cape and much more! <br />Want to know how many
        points you need? Check out the points table below.
        <br />
        <br /><a @click="open('popup-levels1')" class="helpPopupLink"
          >See points table</a
        >
        <br />
        <br />
        <b>Item requirements</b>
        <br />All in-game items now have a level requirement. In the table
        below, you can see some examples of what level you need to be to use
        cool items.<br /><br /><a
          @click="open('popup-levels2')"
          class="helpPopupLink"
        >
          See level requirements</a
        ><br /><br />The Spike Hammer for instance, has a level requirement of
        30, so to equip this item to your minifigure or to buy it from a vendor,
        you need to be level 30. If it was a monster drop, you can still pick it
        up and store it in your Vault until you have leveled up. If you cannot
        wait, you can trade it to a fellow minifigure.<br /><br />Explore LEGO
        Universe to discover all the Universe has to offer, and unlock awesome
        items to fight back the evil Maelstrom and save Imagination.
      </div>
    </text-module>
  </div>
</template>

<script lang="ts">
import { Ref, ref } from "@vue/reactivity";
import Help from "../Help.vue";

interface HelpPopupElement {
    show(): void,
    hide(): void,
}

export default {
  components: { Help },
  setup() {

    const popupLevels1: Ref<any> = ref();
    const popupLevels2: Ref<any> = ref();

    const dict: {[key:string]: Ref<any>} = {
        'popup-levels1': popupLevels1,
        'popup-levels2': popupLevels2,
    };

    function open(key: string): void {
        dict[key].value.open()
    }

    return Object.assign(dict, {
      slides: [
        {
          // picFEAAC83DAD2D646D9D9F4C1DCB0A4431
          img: require("@/assets/slideshow-items/leveling.png"),
          title:
            '<span style="font-size:40px;color:#FFFFFF;margin-bottom:10px;">Leveling</span>',
        },
      ],
      open,
    });
  },
};
</script>