
import { Ref, ref } from "@vue/reactivity";
import Help from "../Help.vue";

interface HelpPopupElement {
    show(): void,
    hide(): void,
}

export default {
  components: { Help },
  setup() {

    const popupLevels1: Ref<any> = ref();
    const popupLevels2: Ref<any> = ref();

    const dict: {[key:string]: Ref<any>} = {
        'popup-levels1': popupLevels1,
        'popup-levels2': popupLevels2,
    };

    function open(key: string): void {
        dict[key].value.open()
    }

    return Object.assign(dict, {
      slides: [
        {
          // picFEAAC83DAD2D646D9D9F4C1DCB0A4431
          img: require("@/assets/slideshow-items/leveling.png"),
          title:
            '<span style="font-size:40px;color:#FFFFFF;margin-bottom:10px;">Leveling</span>',
        },
      ],
      open,
    });
  },
};
